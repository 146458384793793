/* App.css */
html, body, #root {
    height: 100%;
    margin: 0;
  }
  
  .App {
    text-align: center;
    min-height: 100%;
    background-color: #282c34;
    color: white;
    display: flex;
    flex-direction: column;
  }
  
  .App-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: #282c34;
    color: white;
    flex: 0 0 auto;
  }
  
  .file-upload-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .file-upload-container input[type="file"] {
    display: none;
  }
  
  .file-upload-label {
    appearance: button;
    backface-visibility: hidden;
    background-color: #0077FF;
    border-radius: 6px;
    border-width: 0;
    box-shadow: rgba(50, 50, 93, .1) 0 0 0 1px inset,rgba(50, 50, 93, .1) 0 2px 5px 0,rgba(0, 0, 0, .07) 0 1px 1px 0;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    font-family: -apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue",Ubuntu,sans-serif;
    font-size: 120%;
    font-weight: bold;
    height: 52px;
    line-height: 52px;
    margin: 12px 0 0;
    outline: none;
    overflow: hidden;
    padding: 0 35px;
    position: relative;
    text-align: center;
    text-transform: none;
    transform: translateZ(0);
    transition: all .2s, box-shadow .08s ease-in;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: 100%;
  }
  
  .grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    padding: 20px;
    background-color: #282c34;
    overflow-y: auto;
    flex: 1 1 auto;
  }
  
  .grid-item {
    background-color: #fefefe;
    padding: 20px;
    border: 1px solid #888;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: black;
  }
  
  .box-content {
    max-height: 150px;
    overflow-y: auto;
  }
  
  .box-footer {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    gap: 10px;
  }
  
  .box-footer button {
    padding: 4px 8px;
    font-size: 12px;
    cursor: pointer;
  }
  
  .button-9 {
    appearance: button;
    backface-visibility: hidden;
    background-color: #0077FF;
    border-radius: 6px;
    border-width: 0;
    box-shadow: rgba(50, 50, 93, .1) 0 0 0 1px inset,rgba(50, 50, 93, .1) 0 2px 5px 0,rgba(0, 0, 0, .07) 0 1px 1px 0;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    font-family: -apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue",Ubuntu,sans-serif;
    font-size: 120%;
    font-weight: bold;
    height: 52px;
    line-height: 1.15;
    margin: 12px 0 0;
    outline: none;
    overflow: hidden;
    padding: 0 35px;
    position: relative;
    text-align: center;
    text-transform: none;
    transform: translateZ(0);
    transition: all .2s, box-shadow .08s ease-in;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: 100%;
  }
  
  .button-9:disabled {
    cursor: default;
  }
  
  .button-9:focus {
    box-shadow: rgba(50, 50, 93, .1) 0 0 0 1px inset, rgba(50, 50, 93, .2) 0 6px 15px 0, rgba(0, 0, 0, .1) 0 2px 2px 0, rgba(50, 151, 211, .3) 0 0 0 4px;
  }
  
  .grid-item.full-transcription .box-content {
    max-height: 300px;
    overflow-y: auto;
    overflow-x: auto;
    max-width: 100%;
    word-wrap: break-word;
    white-space: pre-wrap;
  }
  
  .preformatted-text {
    white-space: pre-wrap;
    word-wrap: break-word;
  }
  
  .menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  
  .menu-button {
    margin: 10px;
    padding: 10px 20px;
    font-size: 18px;
    background-color: #0077FF;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .back-arrow {
    position: absolute;
    top: 20px;
    left: 20px;
    font-size: 24px;
    background: none;
    border: none;
    color: white;
    cursor: pointer;
  }
  
  .saved-projects ul {
    list-style-type: none;
    padding: 0;
}

.saved-projects li {
  margin: 10px 0;
  padding: 10px;
  background-color: #f0f0f0;
  border-radius: 5px;
  color: #282c34;
}

.generate-content {
  padding: 20px;
}

.error-message {
  color: #ff0000;
  margin-bottom: 20px;
}

.clickable-project {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 10px;
    margin: 5px 0;
    background-color: #f0f0f0;
    border-radius: 5px;
  }
  
  .clickable-project:hover {
    background-color: #e0e0e0;
  }
  
  .delete-button {
    background-color: #ff4d4d;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 3px;
    cursor: pointer;
  }
  
  .delete-button:hover {
    background-color: #ff3333;
  }
  
  .saved-project-details .grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    padding: 20px;
  }
  
  .saved-project-details .grid-item {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
  }

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .modal-content {
    background-color: #282c34;
    padding: 30px;
    border-radius: 10px;
    width: 300px;
    position: relative;
  }
  
  .modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    background: none;
    border: none;
    color: white;
    cursor: pointer;
  }
  
  .modal-content h2 {
    color: white;
    margin-bottom: 20px;
  }
  
  .modal-content form {
    display: flex;
    flex-direction: column;
  }
  
  .modal-content input {
    padding: 10px;
    margin-bottom: 15px;
    border: none;
    border-radius: 5px;
  }
  
  .modal-submit {
    padding: 10px;
    background-color: #0077FF;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .modal-submit:hover {
    background-color: #0066CC;
  }

  .auth-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #282c34;
    color: white;
  }
  
  .auth-container h2 {
    margin-bottom: 20px;
    font-size: 24px;
  }
  
  .auth-container form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 300px;
  }
  
  .auth-container input {
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #3a3f4b;
    color: white;
  }
  
  .auth-container .button-9 {
    margin-top: 10px;
  }
  
  .toggle-auth {
    margin-top: 20px;
    background: none;
    border: none;
    color: #0077FF;
    cursor: pointer;
    text-decoration: underline;
  }
  
  .sign-out-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #f44336;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
  }

  .manage-subscription-button {
    position: absolute;
    top: 10px;
    right: 150px;
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
  }

  .subscription-management {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  
  .subscription-options {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .billing-toggle {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    margin: 0 10px;
  }
  
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:checked + .slider:before {
    transform: translateX(26px);
  }
  
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
  
  .subscribe-button {
    width: 250px;
    margin-top: 20px;
  }
  
  .cancel-subscription {
    margin-top: 20px;
    width: 250px;
  }
  
  .cancel-subscription.inactive {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .subscription-warning {
    color: #ff4d4d;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .menu-button.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .subscription-controls {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .usage-counter {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #f0f0f0;
    padding: 10px;
    border-radius: 5px;
    font-size: 14px;
    color: #333;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  .plans-container {
    display: flex;
    justify-content: space-between;
    margin: 20px 2.5%;
    gap: 20px;
  }
  
  .plan-card {
    background-color: #0077FF;
    border-radius: 10px;
    padding: 20px;
    width: 32%;
    text-align: center;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
  }
  
  .plan-card h2 {
    margin-bottom: 10px;
  }
  
  .plan-card .price {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .plan-card ul {
    list-style-type: none;
    padding: 0;
    flex-grow: 1;
  }
  
  .plan-card li {
    margin-bottom: 10px;
  }
  
  .plan-card .button-9 {
    margin-top: 20px;
    background-color: white;
    color: #0077FF;
  }
  
  .plan-card .button-9.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  .plan-arrow {
    position: absolute;
    bottom: -40px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 20px solid white;
  }
  
  .plan-recommendation {
    position: absolute;
    bottom: -70px;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    font-size: 14px;
    font-weight: bold;
    color: white;
  }

  .annual-billing {
    font-size: 14px;
    color: #e0e0e0;
    margin-top: -15px;
    margin-bottom: 15px;
  }

  .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .loading-text {
    font-size: 24px;
    font-weight: bold;
  }

  .google-sign-in-button {
    display: flex;
    align-items: center;
    background-color: white;
    color: #757575;
    border: 1px solid #dadce0;
    border-radius: 4px;
    padding: 10px 15px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-top: 10px;
  }
  
  .google-sign-in-button:hover {
    background-color: #f7f8f8;
  }
  
  .google-logo {
    width: 18px;
    height: 18px;
    margin-right: 10px;
  }
  
  .google-sign-in-button span {
    flex-grow: 1;
    text-align: center;
  }

  .post-navigator {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

  .post-content {
    flex: 1;
    overflow-y: auto;
    margin-bottom: 10px;
  }

  .navigation-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;
  }

  .nav-button {
    background-color: #0077FF;
    color: white;
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 16px;
  }

  .nav-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }

  .post-indicator {
    font-size: 14px;
    color: #666;
  }

  .guest-bio-content {
    width: 100%;
  }

  .guest-bio-header {
    font-weight: bold;
    font-size: 1.2em;
    margin-bottom: 10px;
  }

  .guest-bio-text {
    white-space: pre-wrap;
    font-family: inherit;
    margin: 0;
  }

  .disabled-input {
    background-color: #f0f0f0;
    color: #888;
    cursor: not-allowed;
  }

  .speaker-input-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    width: 100%;
  }

  .speaker-input-container input[type="text"] {
    flex: 1;
    margin-right: 10px;
  }

  .guest-checkbox-container {
    display: flex;
    align-items: center;
    white-space: nowrap;
  }

  .guest-checkbox-container input[type="checkbox"] {
    margin-right: 5px;
  }

  .grid-item.show-notes-full {
    grid-column: span 2;
    grid-row: span 2;
  }

  .grid-item.show-notes-full .box-content {
    max-height: 400px;
    overflow-y: auto;
  }

  .show-notes-section {
    margin-bottom: 20px;
  }

  .show-notes-section h4 {
    margin-bottom: 10px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 5px;
  }

  .show-notes-guest-bio {
    background-color: #f8f8f8;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 15px;
  }